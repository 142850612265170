body {
  margin: 0px;
  padding: 0px;
  font-family: 'grotesk', px-grotesk, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 1rem;
  letter-spacing: 0.02em;
}

@font-face {
  font-family: "grotesk";
  src: local("px-grotesk"),
  url("./fonts/Px-Grotesk-Regular.woff") format("woff");
  font-weight: normal;
}